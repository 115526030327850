import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeadings from "../components/PageHeadings"
import styled from "styled-components"
import FloorMachinesImage from "../images/orbitz.png"
import S1SeriesImage from "../images/xs-standard-20d-traction-w260.jpg"
import SxSeriesImage from "../images/xs-compact-17d-pa-1-small.jpg"
import SImage from "../images/s-series-disk-white-bg.jpg"
import MImage from "../images/m-series-26disk-white-bg.jpg"
import LImage from "../images/l-series-disk-white-bg.jpg"
import XlImage from "../images/xl-series-disk-white-bg.jpg"
import RSeriesImage from "../images/r-series_0003_r-series-view-1.jpg"
import ProductCard from "../components/ProductCard"
import IndexSection3 from "../components/IndexSection3"

const FloorScrubberStyles = styled.div`
  #my-container {
    display: grid;

    grid-template-columns: 33.3% 33.3% 33.3%;
    justify-items: center;

    @media (max-width: 1000px) {
      grid-template-columns: 100%;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: -ms-flexbox;
      width: 1100px;
      margin-right: auto;
      margin-left: auto;
    }
  }
`

const FloorScrubbersPage = () => {
  return (
    <MainLayout>
      <FloorScrubberStyles>
        <PageHeadings subHeading="Floor Scrubbers" />

        <div id="my-container">
          <ProductCard
            image={FloorMachinesImage}
            title="Floor Machines"
            description="Orbital Floor Scrubbers will clean a variety of applications with easily interchangable pads."
            // tank="Tank - Sol: 12gal (45l) Rec: 12gal (45l)"
            // time="Run Time - Up to 2.5 hours"
            link="/floor-machine-series"
          />
          {/* <ProductCard
            image={SxSeriesImage}
            title="XS Compact"
            description="Cleaning Path - 17 - 20in (43 - 51cm)"
            tank="Tank Sol: 10gal (38L) Rec: 10gal (38L)"
            time="Run Time - Up to 3 hours"
            link="/xs-compact-series"
          /> */}

          {/* "Tank - Sol: 10gal (38l) Rec: 10gal (38l)" */}
          {/* <ProductCard
            image={S1SeriesImage}
            title="XS Standard"
            description="Cleaning Path - 17 - 20in (43 - 51cm)"
            tank="Tank - Sol: 10gal (38L) Rec: 10gal (38L)"
            time="Run Time - Up to 3 hours"
            link="/xs-standard-series"
          /> */}
          <ProductCard
            image={SImage}
            title="S-Series"
            description="Cleaning Path - 17 - 20in (43 - 51cm)"
            tank="Tank - Sol: 12gal (45l) Rec: 15gal (56l)"
            time="Run Time - Up to 2.5 hours"
            link="/s-series"
          />
          <ProductCard
            image={MImage}
            title="M-Series"
            description="Cleaning Path - 20 - 28in (51 - 77cm)"
            tank="Tank - Sol: 16gal (60l) Rec: 16gal (60l)"
            time="Run Time - Up to 3 hours"
            link="/m-series"
          />
          <ProductCard
            image={LImage}
            title="L-Series"
            description="Path - 24 - 28in (9.5 - 77cm)"
            tank="Tank - Sol: 19gal (72l) Rec: 19gal (72l)"
            time="Run Time - Up to 2.5 hours"
            link="/l-series"
          />
          <ProductCard
            image={XlImage}
            title="XL-Series"
            description="Path - 28 - 32in (71 - 81cm)"
            tank="Tank - Sol: 25gal (95l) Rec: 26gal (98l)"
            time="Run Time - Up to 3.5 hours"
            link="/xl-series"
          />
          {/* <ProductCard
            image={RSeriesImage}
            title="R-Series"
            description="Path - 28 - 32in (71 - 81cm)"
            tank="Tank - Sol: 42gal (158l) Rec: 45gal (170l)"
            time="Run Time - Up to 5 hours"
            link="/products/r-series-scrubber"
          /> */}
        </div>
      </FloorScrubberStyles>
      <IndexSection3 title="Request a Free Demo at your Facility" />
    </MainLayout>
  )
}

export default FloorScrubbersPage
